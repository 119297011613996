import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 30rem;
  padding-bottom: 20rem;
`;

export const Left = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 16rem;
    color: white;
  `,
  Title: styled.div`
    margin-top: 13.4rem;
    font-size: 6rem;
    font-weight: 700;
    position: relative;
  `,
  TitleBack: styled.div`
    position: absolute;
    top: 30%;
    left: 0;
    width: 63.7rem;
    height: 2.7rem;
    background: rgba(231, 182, 56, 0.3);
    z-index: -1;
  `,
  SubTitle: styled.div`
    margin-top: 4rem;
    font-size: 4.4rem;
    font-weight: 500;
  `,
  Content: styled.div`
    margin-top: 6rem;
    width: 80rem;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 2.9rem;
  `,
};

export const MenuBtn = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 6rem;
    gap: 2rem;
  `,
  MenuBtn: styled.div`
    border-radius: 1.2rem;
    background: ${(props) => props.$bg};
    padding-left: 2.7rem;
    font-size: 2.3rem;
    font-weight: 700;
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 58.4rem;
    height: 7.5rem;
    cursor: pointer;
  `,

  RightArrow: styled.img`
    width: 4.5rem;
    height: 4.5rem;
  `,
};
export const Link = {
  Wrapper: styled.div`
    margin-top: 5.4rem;
    display: flex;
    gap: 3rem;
  `,
};

export const Right = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4.8rem;
    margin-top: 13rem;
  `,
  ProfileImg: styled.img`
    width: 52.8rem;
    height: 61.3rem;
    border-radius: 2rem;
    background: #d9d9d9;
  `,
  PosWrapper: styled.div`
    display: flex;
    gap: 3rem;
    justify-content: center;
  `,
  PosContainer: styled.div`
    padding: 1.5rem 2.3rem;
    border-radius: 10rem;
    background: #fff;
    font-size: 2rem;
    font-weight: 700;
  `,
};
