import React from "react";
import { Logo, Menu, Wrapper } from "./Nav.style";
import menuData from "../navMenu.json";
import { useLocation, useNavigate } from "react-router";
import { STORAGE_URL } from "../../constant/urlConstant";
export const Nav = () => {
  const nav = useNavigate();
  const location = useLocation();

  const handleNav = (to) => {
    nav(to);
  };

  return (
    <Wrapper>
      <Logo.Wrapper>
        <Logo.LogoImg
          src={`${STORAGE_URL}/portfolio/c02557bf_logo.svg`}
          alt="logo"
        />
        <Logo.LogoText>GRASS IS DEVELOPER’S LIFE</Logo.LogoText>
      </Logo.Wrapper>
      <Menu.Wrapper>
        {menuData.menu.map((v) => (
          <Menu.Text
            key={v.title}
            onClick={() => handleNav(v.path)}
            selected={location.pathname === v.path}
          >
            {v.title}
          </Menu.Text>
        ))}
      </Menu.Wrapper>
    </Wrapper>
  );
};
