import { styled } from "styled-components";

export const Wrapper = styled.div`
  margin: 4rem 16rem;
  padding: 3.6rem 4.5rem;
  width: 160rem;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0.4rem 0.4rem 0.4rem 0.2rem rgba(0, 0, 0, 0.25);
`;

export const Title = styled.div`
  color: #23411f;
  font-size: 2.1rem;
  font-weight: 900;
`;

export const SkillWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.8rem 2.4rem;
  margin-top: 2.5rem;
`;
export const Skill = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 2.5rem;
  border-radius: 100rem;
  background: #e9e9e9;
  font-size: 1.8rem;
  font-weight: 500;
`;
