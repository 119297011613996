import { styled } from "styled-components";

export const Wrapper = styled.div`
  width: 110rem;
  height: 75rem;
  background-color: white;
  padding: 3rem 4rem;
  border-radius: 1rem;
  box-shadow: 0.4rem 0.4rem 0.4rem 0.2rem rgba(0, 0, 0, 0.25);
  overflow: auto;
`;

export const Title = styled.div`
  border-radius: 100rem;
  background: #13400c;
  color: white;
  width: fit-content;
  padding: 1.2rem 2.5rem;
  font-size: 1.7rem;
  font-weight: 700;
`;

export const Top = {
  Wrapper: styled.div`
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
  `,

  Title: styled.div`
    font-size: 1.4rem;
    font-weight: 500;
    & > span {
      font-size: 1.5rem;
      font-weight: 700;
      margin-right: 2rem;
    }
  `,
  Date: styled.div`
    color: #5a5a5a;
    font-size: 1.3rem;
    font-weight: 500;
  `,
  Content: styled.div`
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.7rem;
  `,
};

export const List = {
  Wrapper: styled.div`
    width: 100%;
    margin-top: 2.3rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2.4rem;
  `,
};

export const Detail = {
  Wrapper: styled.div`
    width: 47rem;
    height: 14rem;
    border-radius: 0.7rem;
    background-color: #f5f5f5;
    padding: 2.5rem 2rem;
    overflow: auto;
  `,

  Title: styled.div`
    font-size: 1.3rem;
    font-weight: 700;
  `,
  List: styled.li`
    margin-top: 1rem;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2rem;
  `,
};
