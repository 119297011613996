import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem 16rem 15rem;
  gap: 25rem;
`;

export const BtnWrapper = styled.div`
  display: flex;
  gap: 5rem;
`;

export const Thanks = styled.div`
  color: #fff;
  text-align: right;
  font-size: 12rem;
  font-weight: 900;
  line-height: 13rem;
  width: 100%;
  opacity: 0.5;
`;
