import { styled } from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 20rem;
`;

export const Top = {
  Wrapper: styled.div`
    margin: 4.5rem 0 0 18.7rem;
    display: flex;
    gap: 2.6rem;
    color: white;
    font-size: 2.5rem;
    font-weight: 400;
  `,
  Title: styled.div`
    font-weight: 700;
  `,
};

export const History = {
  Wrapper: styled.div`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-around;
    padding: 0 16rem;
    margin-top: 6rem;
  `,

  Line: styled.div`
    position: absolute;
    top: 40%;
    left: 0;

    width: 100%;
    height: 0.3rem;
    background-color: white;
    z-index: -1;
  `,
};

export const Character = {
  Wrapper: styled.div`
    margin-top: 7rem;
    padding: 0 16rem;
    display: flex;
    gap: 3.7rem;
  `,
};

export const Bottom = {
  Wrapper: styled.div`
    margin: 6.5rem 16rem;
    display: flex;
    gap: 4rem;
  `,
};
