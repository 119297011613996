import React from "react";
import { Detail } from "./CareerCard.style";

export const CareerDetailCard = ({ title, content = [] }) => {
  return (
    <Detail.Wrapper>
      <Detail.Title dangerouslySetInnerHTML={{ __html: title }}></Detail.Title>
      {content.map((v, idx) => (
        <Detail.List key={idx}>{v}</Detail.List>
      ))}
    </Detail.Wrapper>
  );
};
