import { styled } from "styled-components";

export const Wrapper = styled.div`
  margin: 4rem 16rem;
  padding: 3.6rem 4.5rem;
  max-width: 160rem;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0.4rem 0.4rem 0.4rem 0.2rem rgba(0, 0, 0, 0.25);
`;

export const Title = styled.div`
  color: #23411f;
  font-size: 2.1rem;
  font-weight: 900;
`;

export const ProjectsWrapper = styled.div`
  margin-top: 3.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
`;

export const Detail = {
  Wrapper: styled.div`
    width: 47.5rem;
    height: 47rem;
    border-radius: 0.7rem;
    background: #f5f5f5;
    padding: 2.5rem;
    box-shadow: 0.4rem 0.4rem 0.4rem 0.2rem rgba(0, 0, 0, 0.25);
  `,

  Title: styled.div`
    font-size: 2rem;
    font-weight: 700;
  `,
  Position: styled.div`
    margin-top: 1.7rem;
    font-size: 1.7rem;
    font-weight: 500;
  `,
  SkillsWrapper: styled.div`
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
    flex-wrap: wrap;
    height: 8rem;
    overflow: auto;
  `,
  Skills: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    padding: 0.8rem 2rem;
    font-size: 1.5rem;
    font-weight: 500;
    border-radius: 100rem;
    border: 1px solid #c0c0c0;
    background: #e9e9e9;
  `,
  ContentWrapper: styled.div`
    overflow: auto;
    height: 20rem;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,
  Content: styled.li`
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
  `,
  Btn: styled.div`
    margin-top: 2rem;
    color: white;
    display: flex;
    justify-content: center;
    gap: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    width: 100%;
    background-color: #36732c;
    padding: 1.2rem 2.5rem;
    border-radius: 5rem;
  `,
  BtnImg: styled.img`
    width: 0.8rem;
    height: 1.3rem;
  `,
};
