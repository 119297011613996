import React from "react";
import { Detail } from "./MyProjects.style";
import { STORAGE_URL } from "../../constant/urlConstant";

export const ProjectDetail = ({
  imgUrl,
  title,
  position,
  skills = [],
  content = [],
  gitUrl,
}) => {
  const openPage = () => {
    window.open(gitUrl);
  };
  return (
    <Detail.Wrapper>
      <Detail.Title>{title}</Detail.Title>
      <Detail.Position>{position}</Detail.Position>
      <Detail.SkillsWrapper>
        {skills.map((v) => (
          <Detail.Skills key={v}>{v}</Detail.Skills>
        ))}
      </Detail.SkillsWrapper>
      <Detail.ContentWrapper>
        {content.map((v) => (
          <Detail.Content key={v}>{v}</Detail.Content>
        ))}
      </Detail.ContentWrapper>
      <Detail.Btn onClick={openPage}>
        <Detail.BtnImg
          src={`${STORAGE_URL}/portfolio/9522868a_right-arrow-pure.svg`}
          alt="right-arrow"
        />
        <span>자세히 보러가기</span>
      </Detail.Btn>
    </Detail.Wrapper>
  );
};
