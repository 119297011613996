import React from "react";
import { Outlet } from "react-router-dom";
import { Nav } from "../components/Nav/Nav";
import { Background, LayOutContainer, Wrapper } from "./Layout.style";
import { STORAGE_URL } from "../constant/urlConstant";

export const Layout = () => {
  return (
    <Wrapper>
      <Background
        src={`${STORAGE_URL}/portfolio/411305e5_background.webp`}
        alt="grass-bg"
      />

      <Nav />

      <LayOutContainer>
        {/* Pages content : Begin */}
        <Outlet />
        {/* Pages content : End */}
      </LayOutContainer>
    </Wrapper>
  );
};
