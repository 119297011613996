import React from "react";
import { Content, Title, Wrapper } from "./CharacterCard.style";

export const CharacterCard = ({ bg, title, content, pos }) => {
  return (
    <Wrapper $width={pos === "left" ? "70rem" : "86rem"} $bg={bg}>
      <Title>{title}</Title>
      <Content dangerouslySetInnerHTML={{ __html: content }}></Content>
    </Wrapper>
  );
};
