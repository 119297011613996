import React from "react";
import { Top, Wrapper } from "./Skills.style";
import { MySkills } from "../../components/MySkills/MySkills";
import { MyProjects } from "../../components/MyProjects/MyProjects";

export const Skills = () => {
  return (
    <Wrapper>
      <Top.Wrapper>
        <Top.Title>#SKILLS & PROJECTS</Top.Title>
      </Top.Wrapper>

      <MySkills />
      <MyProjects />
    </Wrapper>
  );
};
