import { styled } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  padding-top: 7.7rem;
`;
export const LayOutContainer = styled.div`
  width: 100%;
  height: 100%;
`;
export const Background = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -999;
`;
