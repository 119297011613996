import { styled } from "styled-components";

export const Wrapper = styled.div`
  width: 160rem;
  height: 8.4rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #36732c;
  padding: 0 7.5rem 0 6.7rem;
  border-radius: 1rem;
`;

export const Logo = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 1.7rem;
  `,

  LogoImg: styled.img`
    width: 3.2rem;
    height: 2.5rem;
  `,

  LogoText: styled.div`
    color: #fff;
    font-size: 2.5rem;
    font-weight: 700;
  `,
};
export const Menu = {
  Wrapper: styled.div`
    display: flex;
    gap: 8rem;
  `,

  Text: styled.div`
    color: white;
    font-size: 1.8rem;
    font-weight: 500;
    cursor: pointer;
    color: ${(props) => (props.selected ? "white" : "#add6a6")};

    &:hover {
      color: white;
    }
  `,
};
