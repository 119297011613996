import { styled } from "styled-components";

export const Wrapper = styled.div`
  padding: 4rem;
  height: 28rem;
  width: ${(props) => props.$width};
  background-color: ${(props) => props.$bg};
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: white;
  border-radius: 1rem;
  box-shadow: 0px 0.4rem 0.4rem 0px rgba(0, 0, 0, 0.25);
`;

export const Title = styled.div`
  font-size: 1.9rem;
  font-weight: 700;
`;
export const Content = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
`;
