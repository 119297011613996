import React from "react";
import { Bottom, Character, History, Top, Wrapper } from "./About.style";
import { HistoryCard } from "../../components/HistoryCard/HistoryCard";
import { CharacterCard } from "../../components/CharacterCard/CharacterCard";
import historyJson from "./history.json";
import characterJson from "./character.json";
import { ProfileCard } from "../../components/ProfileCard/ProfileCard";
import { CareerCard } from "../../components/CareerCard/CareerCard";
export const About = () => {
  return (
    <Wrapper>
      <Top.Wrapper>
        <Top.Title>#ABOUT ME</Top.Title>
        <div>TAESEOK PARK</div>
      </Top.Wrapper>

      <History.Wrapper>
        <History.Line />
        {historyJson.history.map((v) => (
          <HistoryCard
            key={v.title + v.sub}
            date={v.date}
            title={v.title}
            sub={v.sub}
          />
        ))}
      </History.Wrapper>

      <Character.Wrapper>
        {characterJson.character.map((v) => (
          <CharacterCard
            bg={v.bg}
            title={v.title}
            content={v.content}
            pos={v.pos}
          />
        ))}
      </Character.Wrapper>

      <Bottom.Wrapper>
        <ProfileCard />
        <CareerCard />
      </Bottom.Wrapper>
    </Wrapper>
  );
};
