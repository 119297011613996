import React from "react";
import { Btn, Logo, Title, Wrapper } from "./LinkBtn.style";
import { STORAGE_URL } from "../../constant/urlConstant";

export const LinkBtn = ({ url, logo, text, btnBg }) => {
  const openLink = () => {
    window.open(url);
  };
  return (
    <Wrapper>
      <Logo src={logo} alt="logo" />
      <Title>{text}</Title>
      <Btn.Wrapper onClick={openLink} $bg={btnBg}>
        <Btn.Arrow
          src={`${STORAGE_URL}/portfolio/9522868a_right-arrow-pure.svg`}
          alt="right-arrow"
        />
        <Btn.Text>자세히 보러가기</Btn.Text>
      </Btn.Wrapper>
    </Wrapper>
  );
};
