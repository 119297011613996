import React from "react";
import { Background, Profile, Wrapper } from "./ProfileCard.style";
import { STORAGE_URL } from "../../constant/urlConstant";

export const ProfileCard = () => {
  return (
    <Wrapper>
      <Background
        src={`${STORAGE_URL}/portfolio/78eab8ab_profile-image.png`}
        alt="profile-image"
      />
      <Profile.Wrapper>
        <Profile.Title>PROFILE</Profile.Title>
        <Profile.ContentWrapper>
          <Profile.ContentName>
            <div>이름</div>
            <div>생년월일</div>
            <div>이메일</div>
            <div>학력</div>
          </Profile.ContentName>
          <Profile.Content>
            <div>박태석</div>
            <div>1996.08.17</div>
            <div>qkrxotjr9508@gmail.com</div>
            <div>한국공학대학교 생명화학공학과</div>
          </Profile.Content>
        </Profile.ContentWrapper>
      </Profile.Wrapper>
    </Wrapper>
  );
};
