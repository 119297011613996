import React from "react";
import { BtnBig, LogoBig, TitleBig, WrapperBig } from "./LinkBtn.style";
import { STORAGE_URL } from "../../constant/urlConstant";

export const LinkBtnBig = ({ url, logo, text, btnBg }) => {
  const openLink = () => {
    window.open(url);
  };
  return (
    <WrapperBig>
      <LogoBig src={logo} alt="logo" />
      <TitleBig>{text}</TitleBig>
      <BtnBig.Wrapper onClick={openLink} $bg={btnBg}>
        <BtnBig.Arrow
          src={`${STORAGE_URL}/portfolio/9522868a_right-arrow-pure.svg`}
          alt="right-arrow"
        />
        <BtnBig.Text>자세히 보러가기</BtnBig.Text>
      </BtnBig.Wrapper>
    </WrapperBig>
  );
};
