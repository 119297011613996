import React from "react";
import { BtnWrapper, Thanks, Wrapper } from "./Archiving.style";
import { LinkBtnBig } from "../../components/LinkBtn/LinkBtnBig";
import { STORAGE_URL } from "../../constant/urlConstant";

export const Archiving = () => {
  return (
    <Wrapper>
      <BtnWrapper>
        <LinkBtnBig
          url={"https://github.com/TeTedo"}
          logo={`${STORAGE_URL}/portfolio/5aebcfe9_github-logo.png`}
          text={"개발소스 코드 저장소"}
          btnBg={"#222"}
        />
        <LinkBtnBig
          url={"https://blog.tetedo.com/"}
          logo={`${STORAGE_URL}/portfolio/1224ada9_tistory-logo.png`}
          text={"개발 개인 스터디 및 공유 블로그"}
          btnBg={"#EA5220"}
        />
      </BtnWrapper>
      <Thanks>
        THANKS FOR WATCHING <br /> MY PORTFOLIO
      </Thanks>
    </Wrapper>
  );
};
