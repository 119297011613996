import { styled } from "styled-components";

export const Wrapper = styled.div`
  width: 45rem;
  height: 75rem;
  display: flex;
  position: relative;
`;

export const Background = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export const Profile = {
  Wrapper: styled.div`
    padding: 2.5rem;
    width: 40rem;
    height: 25rem;
    margin: 2.7rem auto;

    border-radius: 1rem;
    background: rgba(252, 252, 252, 0.95);
    box-shadow: 0px 0.4rem 0.4rem 0px rgba(0, 0, 0, 0.1);
  `,
  Title: styled.div`
    color: #23411f;
    font-size: 2rem;
    font-weight: 900;
  `,
  ContentWrapper: styled.div`
    margin-top: 2.5rem;
    display: flex;
    gap: 3.5rem;
  `,
  ContentName: styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.3rem;
    font-size: 1.4rem;
    font-weight: 700;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.3rem;
    font-size: 1.4rem;
    font-weight: 500;
  `,
};
