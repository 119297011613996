import React from "react";
import { Circle, Date, Sub, Title, Wrapper } from "./HistoryCard.style";
import { STORAGE_URL } from "../../constant/urlConstant";

export const HistoryCard = ({ date, title, sub }) => {
  return (
    <Wrapper>
      <Date>{date}</Date>
      <Circle
        src={`${STORAGE_URL}/portfolio/0a9d6f69_history-circle.svg`}
        alt="circle"
      />
      <Title>{title}</Title>
      <Sub>{sub}</Sub>
    </Wrapper>
  );
};
