import { styled } from "styled-components";

export const Wrapper = styled.div`
  border-radius: 1.5rem;
  background: #fdfdfd;
  width: fit-content;
  padding: 2.5rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
`;

export const Logo = styled.img`
  width: 13.3rem;
  height: 3rem;
`;
export const Title = styled.div`
  color: black;
  font-size: 1.8rem;
  font-weight: 700;
  height: 3rem;

  white-space: nowrap;
`;
export const Btn = {
  Wrapper: styled.div`
    border-radius: 5rem;
    background: ${(props) => props.$bg};
    padding: 1rem 3rem;
    display: flex;
    align-items: center;
    gap: 1.1rem;
    width: 20rem;
    cursor: pointer;
  `,
  Arrow: styled.img`
    width: 1rem;
    height: 1.6rem;
  `,

  Text: styled.div`
    font-size: 1.4rem;
    font-weight: 700;
  `,
};

export const WrapperBig = styled(Wrapper)`
  width: 77.2rem;
  gap: 3.5rem;
  color: white;
`;

export const TitleBig = styled(Title)`
  font-size: 2.5rem;
`;

export const LogoBig = styled(Logo)`
  width: 25rem;
  height: 5rem;
`;

export const BtnBig = {
  Wrapper: styled(Btn.Wrapper)`
    border-radius: 6.5rem;
    background: ${(props) => props.$bg};
    padding: 1.3rem 4rem;
    gap: 1.4rem;
    width: 23rem;
  `,
  Arrow: styled(Btn.Arrow)`
    width: 1.3rem;
    height: 2rem;
  `,

  Text: styled(Btn.Text)`
    font-size: 1.9rem;
    width: fit-content;
    white-space: nowrap;
  `,
};
