import React from "react";
import { ProjectsWrapper, Title, Wrapper } from "./MyProjects.style";
import projectJson from "../../pages/skills/project.json";
import { ProjectDetail } from "./ProjectDetail";
export const MyProjects = () => {
  return (
    <Wrapper>
      <Title>MY PROJECTS</Title>
      <ProjectsWrapper>
        {projectJson.projects.map((v) => (
          <ProjectDetail
            key={v.title}
            imgUrl={v.imgUrl}
            title={v.title}
            position={v.position}
            skills={v.skills}
            content={v.content}
            gitUrl={v.gitUrl}
          />
        ))}
      </ProjectsWrapper>
    </Wrapper>
  );
};
