import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
`;

export const Date = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  width: 5rem;
`;
export const Circle = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  margin-top: 1rem;
`;
export const Title = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
  margin-top: 2rem;
`;
export const Sub = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 1rem;
`;
