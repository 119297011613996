import { styled } from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 20rem;
`;

export const Top = {
  Wrapper: styled.div`
    margin: 4.5rem 0 0 18.7rem;
    display: flex;
    gap: 2.6rem;
    color: white;
    font-size: 2.5rem;
    font-weight: 400;
  `,
  Title: styled.div`
    font-weight: 700;
  `,
};
