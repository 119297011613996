import React from "react";
import { LinkBtn } from "../../components/LinkBtn/LinkBtn";
import { Left, Link, MenuBtn, Right, Wrapper } from "./Main.style";
import { useNavigate } from "react-router";
import { STORAGE_URL } from "../../constant/urlConstant";

export const Main = () => {
  const nav = useNavigate();

  const navToOtherPage = (to) => {
    nav(to);
  };
  return (
    <Wrapper>
      <Left.Wrapper>
        <Left.Title>
          오늘도 잔디를 심습니다.
          <Left.TitleBack />
        </Left.Title>
        <Left.SubTitle>잔디심는 개발자 박태석입니다.</Left.SubTitle>
        <Left.Content>
          개발에 대한 높은 열정으로 뛰어난 성과를 내기 위해 매일 잔디를 심고있는
          개발자 박태석입니다. <br /> 현재 1년차 개발자로서 경험을 쌓아오면서
          창의적이고 효율적인 문제 해결 능력을 기를 수 있었습니다.
        </Left.Content>
        {/* MenuBtn */}
        <MenuBtn.Wrapper>
          <MenuBtn.MenuBtn
            $bg={"#5C9B52"}
            onClick={() => navToOtherPage("/about")}
          >
            <MenuBtn.RightArrow
              src={`${STORAGE_URL}/portfolio/fdc5e175_right-arrow.svg`}
              alt="right-arrow"
            />
            <div>ABOUT ME</div>
          </MenuBtn.MenuBtn>
          <MenuBtn.MenuBtn
            $bg={"#5C9B52"}
            onClick={() => navToOtherPage("/skills")}
          >
            <MenuBtn.RightArrow
              src={`${STORAGE_URL}/portfolio/fdc5e175_right-arrow.svg`}
              alt="right-arrow"
            />
            <div>SKILLS & PROJECTS</div>
          </MenuBtn.MenuBtn>
        </MenuBtn.Wrapper>
        {/* Link */}
        <Link.Wrapper>
          <LinkBtn
            url={"https://github.com/TeTedo"}
            logo={`${STORAGE_URL}/portfolio/5aebcfe9_github-logo.png`}
            text={"개발소스 코드 저장소"}
            btnBg={"#222"}
          />
          <LinkBtn
            url={"https://blog.tetedo.com/"}
            logo={`${STORAGE_URL}/portfolio/1224ada9_tistory-logo.png`}
            text={"개발 개인 스터디 및 공유 블로그"}
            btnBg={"#EA5220"}
          />
        </Link.Wrapper>
      </Left.Wrapper>
      <Right.Wrapper>
        <Right.ProfileImg
          src={`${STORAGE_URL}/portfolio/69cacc34_profile-golf.png`}
          alt="profile-golf"
        />
        <Right.PosWrapper>
          <Right.PosContainer>서버/백엔드 개발자</Right.PosContainer>
        </Right.PosWrapper>
      </Right.Wrapper>
    </Wrapper>
  );
};
