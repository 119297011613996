import React from "react";
import { Skill, SkillWrapper, Title, Wrapper } from "./MySkills.style";
import skillJson from "../../pages/skills/skill.json";
export const MySkills = () => {
  return (
    <Wrapper>
      <Title>MY SKILLS</Title>
      <SkillWrapper>
        {skillJson.skills.map((v) => (
          <Skill key={v}>{v}</Skill>
        ))}
      </SkillWrapper>
    </Wrapper>
  );
};
